import UploadAdapter from '@/assets/js/editor/class.upload.adapter';
import ClassicEditor from '@/assets/js/editor/class.ckeditor';

const editorConfig = {
    heading: {
        options: [
            {model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph'},
            {model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1'},
            {model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2'},
        ],
    },
    toolbar: {
        items: [
            'heading',
            'bold',
            'italic',
            'underline',
            'fontColor',
            'fontBackgroundColor',
            '|',
            'alignment:left',
            'alignment:center',
            'alignment:right',
            'imageUpload',
            'insertTable',
        ],
        shouldNotGroupWhenFull: true,
    },
    readOnly: true,
    timestamp: new Date(),
    language: 'ko',
    mediaEmbed: {previewsInData: true},
};

export const setPageEditor = (pageEditor, tryCount, callback, endpoint) => {
    if (tryCount > 10) return;
    if (pageEditor) {
        ClassicEditor.create(pageEditor, editorConfig)
            .then(editor => {
                editor.plugins.get('FileRepository').createUploadAdapter = loader => {
                    return new UploadAdapter(loader, endpoint);
                };
                if (callback instanceof Function) callback(editor);
            })
            .catch(error => {
                console.error(error);
            });
    } else {
        tryCount++;
        setTimeout(() => {
            setPageEditor();
        }, 200);
    }
};
